var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"visible":_vm.isSidebarActive,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","no-close-on-backdrop":"","no-close-on-esc":"","right":""},on:{"hidden":_vm.resetForm,"change":function (val) { return _vm.$emit('update:is-sidebar-active', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_c('strong',[_vm._v(_vm._s(_vm.sidebarMode == 'add' ? 'AGREGAR SUBSIDIARIA' : 'EDITAR SUBSIDIARIA'))])]),(!_vm.processing)?_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}}):_vm._e()],1),_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return _vm.resetForm($event)}}},[_c('b-form-group',{attrs:{"label":"NOMBRE EMPRESA","label-for":"name"}},[_c('validation-provider',{attrs:{"rules":"required","name":"nombre"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","placeholder":"Ingrese el nombre ...","state":errors.length > 0 ? false : null,"trim":""},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"RUC","label-for":"ruc"}},[_c('validation-provider',{attrs:{"rules":"required","name":"RUC"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"ruc","placeholder":"Ingrese el RUC ...","state":errors.length > 0 ? false : null,"trim":""},model:{value:(_vm.formData.ruc),callback:function ($$v) {_vm.$set(_vm.formData, "ruc", $$v)},expression:"formData.ruc"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button-loading',{attrs:{"text":_vm.sidebarMode == 'add' ? 'AGREGAR' : 'ACTUALIZAR',"type":"submit","processing":_vm.processing}}),(!_vm.processing)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"ml-2",attrs:{"type":"button","variant":"outline-secondary"},on:{"click":hide}},[_vm._v(" CANCELAR ")]):_vm._e()],1)],1)]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }